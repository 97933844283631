<template>
  <div style="width: 100%;height: calc(100vh - 140px);">
<!--    <div style="text-align: center;font-size: 3em;padding-top: 15%">待办事项</div>-->
<!--    <div style="float: left;width: 50%;padding-top: 50px">-->
<!--      <div  style="text-align: right;padding-right: 50px;font-size: 2em;">-->
<!--        <span>待接单</span><br/>-->
<!--        <span style="color:red;text-align: left;padding-right: 10px">-->
<!--          <a-button type="link" style="color: red;font-size: 1em" @click="gotoRoute('orderTaking',10)">{{ count }}</a-button>-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div style="float: right;width: 50%;padding-top: 50px">-->
<!--      <div  style="text-align: left;padding-left: 50px;font-size: 2em;">-->
<!--        <span>跟进事项</span><br/>-->
<!--        <span style="color:red;text-align: left;padding-left: 20px">-->
<!--        <a-button type="link" style="color: red;font-size: 1em" @click="gotoRoute('waiting_order',20,0)">{{ mscount }}</a-button>-->
<!--        </span>-->
<!--      </div>-->
<!--  </div>-->
    <div id="bindwx_div" v-show="showbindwx" style="height: 30px;background: orange;padding-left: 10px;padding-top:3px;">
      <div style="float: left;width:calc(100vw - 450px)">
        <img style="width: 20px;height: 20px" src="https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/gantanhao.png" />
        <b style="font-weight: bold;padding-left: 10px;color: white; ">推荐绑定微信，使用微信扫一扫登录后台</b>
      </div>
      <div style="float: left;width: 40px;"> <a-button type="primary" size="small" style="background-color: white;color: orange;border: white" @click="gotoroute('center')">立即绑定</a-button></div>
      <div style="float: right;width:30px;vertical-align: center;text-align: right"> <a-icon type="close-circle" style="padding-right: 10px;padding-top:4px;color:midnightblue;fontSize:18px;" @click="closebdwx" /></div>


    </div>

    <div style="padding-top: 10px">

      <table style="width: 100%">
        <!--左右布局-->
        <tr>
          <td>
            <div style="height: 100px">
              <table style="width: 98%;background: white;height: 100%" id="tb_wait">
                <tr><td class="tb_title">待接单</td> <td class="tb_title">待提货/分配交仓</td> <td class="tb_title">待入库</td> <td class="tb_title">待关联单号</td> <td class="tb_title">待交仓</td> <td class="tb_title">待二次确认</td> <td class="tb_title">待跟进</td></tr>
                <tr>
                  <td><a @click="gotoroute('orderTaking')">{{ waitwork.waitOrderNum }}</a></td>
                  <td><a @click="gotoroute('Less_load')">{{ waitwork.loadOrderNum }}</a></td>
                  <td><a @click="gotoroute('inStorage')">{{ waitwork.inStorageOrderNum }}</a></td>
                  <td><a @click="gotoroute('Less_transport')">{{ waitwork.lessOrderNum }}</a></td>
                  <td><a @click="gotoroute('changehouse_transport')">{{ waitwork.changehouseOrderNum }}</a></td>
                  <td><a @click="gotoroute('secondaryOrderConfirm')">{{ waitwork.secConfirmNum }}</a></td>
                  <td><a @click="gotoroute('waiting_order')">{{ waitwork.uReadMessageNum }}</a></td>
                </tr>
              </table>
            </div>

            <div style="height:calc(100vh - 490px);margin-top: 5px;margin-bottom: 5px" >

              <table style="width: 98%;background: white;height:100%" id="tb_data_show">
                <tr><td style="text-align: left;height: 15px;padding-left: 10px;padding-top: 5px;font-weight: bold" colspan="4">
                  数据概览 <img id="canshowimg" :src="canshowpic" style="width: 20px;height: 20px;" @click="changeCanshow">
                </td></tr>
                <tr>
                  <td class="tb_title">今日订单</td> <td class="tb_title" style="border-right: 1px solid #d6d6d6">今日完成</td>
                  <td class="tb_title">今日订单金额</td> <td class="tb_title">散户累计金额</td>
                </tr>
                <tr>
                  <td>{{ canshowdata? statisticsdata.newOrderSum : "--"}}</td>
                  <td style="border-right: 1px solid #d6d6d6">{{ canshowdata? statisticsdata.finshOrderSum : "--"}}</td>
                  <td>{{ canshowdata? statisticsdata.orderAmount : "--"}}</td>
                  <td>{{ canshowdata? statisticsdata.hisOtherOrderAmount : "--"}}</td>
                </tr>
                <tr>
                  <td>累计订单</td> <td style="border-right: 1px solid #d6d6d6">累计完成</td>
                  <td>累计订单金额</td> <td>企业客户累计金额</td>
                </tr>
                <tr>
                  <td>{{ canshowdata? statisticsdata.hisOrderSum : "--"}}</td>
                  <td style="border-right: 1px solid #d6d6d6">{{ canshowdata? statisticsdata.hisFinshOrderSum : "--"}}</td>
                  <td>{{ canshowdata? statisticsdata.hisOrderAmount : "--"}}</td>
                  <td>{{ canshowdata? statisticsdata.hisQyCustomerOrderAmount : "--"}}</td>
                </tr>
              </table>
            </div>

            <div style="height: 230px;width:calc(100vw - 665px)">
              <div style="background: white;width: 98%;height: 100%;" >
                <div style="height: 30px;padding-top: 5px;padding-left: 10px;font-weight: bold">企业客户</div>
                <s-table
                  v-show="canshowcustomer"
                  ref="table"
                  size="small"
                  :rowKey="(record) => record.id"
                  :columns="columns"
                  :pagination="pagination"
                  :data="loadData"
                  :pageSize="5"
                  showPagination="auto"
                  :scroll="{ x:'max-content', y: 160}"
                  style="margin-left: 10px;width: 98%"
                >
                    <span slot="action" slot-scope="text, record">
                            <template>
                              <a @click="edit(record)" v-action:setqycustomertop>{{ record.top==1?"取消置顶":"置顶" }}</a>
                            </template>
                    </span>
                </s-table>
                <div v-show="!canshowcustomer" style="width: 90%;text-align: center;margin-top: 50px"><img style="width: 100px;height: 120px" src="https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/noperm.png"></div>

              </div>
            </div>
          </td>

          <td style="width: 360px;vertical-align: top;">
            <div style="width: 360px;height: 140px;background: #d6d6d6;overflow-x:hidden;overflow-y: hidden">
              <a-carousel autoplay :dotPosition="position" :dots="true" style="height: 140px">
                <a   v-for="(item, index) in bannerList" :key="index" target="_blank" :href="item.linkUrl">
                  <img style="height:100%;width:100%" :src="item.imgUrl"></img>
                </a>
              </a-carousel>
            </div>
            <div style="height:calc(100vh - 400px);background: white ;margin-top: 5px;margin-bottom: 5px;overflow-y: hidden" v-show="canshowmoremes">
              <div style="height: 40px;background-color: dodgerblue; border-radius:10px;margin-top: 10px;padding-top: 10px">
                <table>
                  <tr>
                    <td style="width: 40px"><a-icon style="color: blue;fontSize:20px; weight:30px;height: 30px;margin-left: 10px;margin-right: 10px" type="sound" /></td>
                    <td style="vertical-align: top;width: 280px"><b style="font-weight: bold;color: white;;">冰鸽平台信息</b></td>
                    <td style="vertical-align: top"><b style="font-weight: bold;color: #d6d6d6;" @click="gotoroute('SysMessage')">更多</b></td>
                  </tr>
                </table>

              </div>
              <div v-for="(item,index) in messageList" :key="index" style="padding-top: 10px;padding-bottom: 10px;padding-right: 10px">
                <div style="padding-left: 10px;">
                  <a target="_blank"  :href="item.url">
                    <b style="color: #00A0E9">●</b><b style="font-weight: bold">{{item.title}}</b>
                  </a>
                </div>
                <div style="padding-left: 20px;width:350px;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.content}}</div>
              </div>

            </div>
            <div style="height: 100px;width:100%;background: white;">
              <table style="width: 100%;height: 100%">
                <tr>
                  <td>
                    <div style="height: 30px;text-align: center">老板您好我是您的<b style="color: #1890ff">专属顾问</b></div>
                    <div style="height: 30px;text-align: center">
<!--                      <a-button>微信咨询</a-button>-->
                      <a-popover title="微信咨询" placement="top">
                        <template slot="content">
                          <div>
                            <img id="zfkfqrcodezfkfqrcode" style="width: 150px;height: 150px" :src="csqrcodeurl"/>
                            <div style="text-align: center">联系电话：{{ cstel }}</div>
                          </div>
                        </template>
                        <a-button type="primary">
                          微信咨询
                        </a-button>
                      </a-popover>
                    </div>
                  </td>
                  <td style="width: 80px;height: 80px"><img style="height: 100%;width: 100%;" src="https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/zskf.png"/></td>
                </tr>
              </table>
            </div>
          </td>
        </tr>


      </table>
    </div>

  </div>
</template>

<script>
import { timeFix } from '@/utils/util'
import { mapState } from 'vuex'

import { PageView } from '@/layouts'
import HeadInfo from '@/components/tools/HeadInfo'
import { Radar } from '@/components'
import axios from "axios";
import Vue from "vue";
import {ACCESS_TOKEN} from "@/store/mutation-types";
import {
  countMes,
  findPageNotice, findPermitionByCode,
  getUserInfo, settop,
  statisticcustomergroup, statisticsbanner,
  statisticsdata, statisticsmessage,
  statisticswork
} from "@/api/manage";
import moment from 'moment'
import { STable } from '@/components'
import QRCode from "qrcodejs2";
const DataSet = require('@antv/data-set')

export default {
  name: 'Workplace',
  components: {
    STable
  },
  data () {
    return {
      pagination: {pageSizeOptions: ['5'],size:"small"},
      position:"bottom",
      columns: [
        {
          title: '企业客户',
          width: '150px',
          key: 'nickname',
          align: 'center',
          dataIndex: 'nickname',
        },
        {
          title: '累计订单',
          width: '80px',
          key: 'orderCount',
          align: 'center',
          dataIndex: 'orderCount',
        },
        {
          title: '累计重量',
          width: '150px',
          key: 'weightSum',
          align: 'center',
          dataIndex: 'weightSum',
        },
        {
          title: '近7天订单',
          width: '80px',
          key: 'day7OrderCount',
          align: 'center',
          dataIndex: 'day7OrderCount',
        },
        {
          title: '近7天重量',
          width: '150px',
          key: 'day7weightSum',
          align: 'center',
          dataIndex: 'day7weightSum',
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          align: 'center',
          width: '120px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      count:0,
      mscount:0,
      showbindwx:false,
      bannerList:[],
      csqrcodeurl:null,
      cstel:"",
      waitwork:{waitOrderNum:"--"},
      statisticsdata:{newOrderSum:0},
      messageList:[],
      canshowperm:false,
      canshowdata:false,
      canshowcustomer:false,
      canshowmoremes:false,
      canshowpic:"https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/ucanshow.png"
    }
  },
  computed: {

  },
  created () {

  },
  mounted () {
    let that = this
    this.initUserInfo();
    this.initwork();
    this.initdata();
    this.initbanner();
    this.initmessage();
    let csQrcode = Vue.ls.get("csQrcode")
    let cstel0 = Vue.ls.get("csTel")
    this.csqrcodeurl = "https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/"+csQrcode;
    //this.csqrcodeurl = "https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/zskf.png"
    if(cstel0!=null){
      this.cstel = cstel0
    }
    //判断是否有数据中心显示权限
    findPermitionByCode({code:"statisticsdata"}).then((res) => {
      if(res!=null&&res.result!=null){
        that.canshowperm = true//有权限显示数据
        that.canshowdata = true
        that.canshowpic = "https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/canshow.png"
      }
    })
    findPermitionByCode({code:"customerstatisticsdata"}).then((res) => {
      if(res!=null&&res.result!=null){
        that.canshowcustomer = true
      }
    })

    findPermitionByCode({code:"SysMessage"}).then((res) => {
      if(res!=null&&res.result!=null){
        that.canshowmoremes = true
      }
    })


  },
  methods: {
    initwork:function (){
      let that = this;
      //工作区数据
      let param = {}
      statisticswork(param).then((res) => {
        let result = res.result
        if(result.permitionCodeList!=null){
          if(result.permitionCodeList.indexOf("orderTaking")>=0){
            that.waitwork = {}
            that.waitwork.waitOrderNum = result.waitOrderNum
          }else {
            that.waitwork.waitOrderNum = "--"
          }
          if(result.permitionCodeList.indexOf("Less_load")>=0){
            that.waitwork.loadOrderNum = result.loadOrderNum
          }else {
            that.waitwork.loadOrderNum = "--"
          }
          if(result.permitionCodeList.indexOf("inStorage")>=0){
            that.waitwork.inStorageOrderNum = result.inStorageOrderNum
          }else {
            that.waitwork.inStorageOrderNum = "--"
          }
          if(result.permitionCodeList.indexOf("Less_transport")>=0){
            that.waitwork.lessOrderNum = result.lessOrderNum
          }else {
            that.waitwork.lessOrderNum = "--"
          }
          if(result.permitionCodeList.indexOf("changehouse_transport")>=0){
            that.waitwork.changehouseOrderNum = result.changehouseOrderNum
          }else {
            that.waitwork.changehouseOrderNum = "--"
          }
          if(result.permitionCodeList.indexOf("secondaryOrderConfirm")>=0){
            that.waitwork.secConfirmNum = result.secConfirmNum
          }else {
            that.waitwork.secConfirmNum = "--"
          }
          if(result.permitionCodeList.indexOf("waiting_order")>=0){
            that.waitwork.uReadMessageNum = result.uReadMessageNum==null?0:result.uReadMessageNum
          }else {
            that.waitwork.uReadMessageNum = "--"
          }
        }

      })
    },
    initdata(){
      let that = this;
      //工作区数据
      let param = {}
      statisticsdata(param).then((res) => {
        let result = res.result
        if(result!=null){
          that.statisticsdata = {}
          that.statisticsdata.newOrderSum = result.newOrderSum
          that.statisticsdata.finshOrderSum = result.finshOrderSum
          that.statisticsdata.orderAmount = result.orderAmount
          that.statisticsdata.netIncome = result.netIncome
          that.statisticsdata.hisOrderSum = result.hisOrderSum
          that.statisticsdata.hisFinshOrderSum = result.hisFinshOrderSum
          that.statisticsdata.hisOrderAmount = result.hisOrderAmount
          that.statisticsdata.hisNetIncome = result.hisNetIncome
          that.statisticsdata.hisQyCustomerOrderAmount = result.hisQyCustomerOrderAmount
          that.statisticsdata.hisOtherOrderAmount = result.hisOtherOrderAmount
        }

      })
    },
    initbanner(){
      let that = this;
      //工作区数据
      let param = {type:30,onlineState:1}
      statisticsbanner(param).then((res) => {
        let result = res.result
        that.bannerList = []
        for(var i=0;i<result.length;i++){
          var imgUrl = "https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/"+result[i].imgUrl
          var linkUrl = result[i].linkUrl
          result[i].imgUrl = imgUrl
          that.bannerList.push(result[i])
        }
      })
    },
    initmessage(){
      let that = this;
      //工作区数据
      let param = {type:30,onlineState:1}
      statisticsmessage(param).then((res) => {
        let result = res.result
        that.messageList = result
      })
    },
    loadData: (parameter) => {
      console.log('loadData.parameter', parameter)
      parameter.pageSize=5
      return statisticcustomergroup(parameter).then((res) => {
        return res.result
      })
    },
    initUserInfo() {
      getUserInfo({})
        .then((res) => {
          if (res.result != null) {
            this.userInfo = {}
            this.userInfo.name = res.result.name
            if (res.result.openId == null || res.result.openId == '') {
              let closebindwxdiv = Vue.ls.get("closebindwxdiv");
              if(1!=closebindwxdiv){
                this.showbindwx=true
              }
            }
          }
        })
        .catch((e) => {
          this.$message.error('查询当前登录信息失败')
        })
    },
    edit(e){
      let that = this
      let targettop = 0
      if(e.top==1){
        targettop = 0
      }else{
        targettop = 1
      }
      settop({id:e.id,top:targettop})
        .then((res) => {
          this.$refs.table.refresh()
        })
    },
    gotoroute(routeName){
      if(routeName=="orderTaking"){
        if(this.waitwork.waitOrderNum != "--"){
          this.$router.push({ name: routeName })
        }
      }else if(routeName=="Less_load"){
        if(this.waitwork.loadOrderNum != "--"){
          this.$router.push({ name: routeName , params: {"loadStatus": 0}})
        }

      }else if(routeName=="inStorage"){
        if(this.waitwork.inStorageOrderNum != "--"){
          this.$router.push({ name: routeName , params: {"loadStatus": 1}})
        }

      }else if(routeName=="Less_transport"){
        if(this.waitwork.lessOrderNum != "--"){
          this.$router.push({ name: routeName , params: {"transStatus": 0}})
        }

      }else if(routeName=="changehouse_transport"){
        if(this.waitwork.changehouseOrderNum != "--"){
          this.$router.push({ name: routeName , params: {"changeWareStatus": 0}})
        }

      }else if(routeName=="secondaryOrderConfirm"){
        if(this.waitwork.secConfirmNum != "--"){
          this.$router.push({ name: routeName })
        }

      }else if(routeName=="waiting_order"){
        if(this.waitwork.uReadMessageNum != "--"){
          this.$router.push({ name: routeName , params: {"type": 20,readed:0}})
        }

      }else{
        this.$router.push({ name: routeName })
      }

    },
    closebdwx(){
      Vue.ls.set("closebindwxdiv",1);
      this.showbindwx=false
    },
    changeCanshow(){
      if(this.canshowperm == true){//有权限操作
        if(this.canshowdata == false){
          this.canshowdata = true
          this.canshowpic = "https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/canshow.png"
        }else{
          this.canshowdata = false
          this.canshowpic =  "https://yihlengyun-public.oss-cn-beijing.aliyuncs.com/icon/ucanshow.png"
        }
      }

    }

  },

  beforeRouteLeave(to, from, next) {
    // 销毁组件，避免通过vue-router再次进入时，仍是上次的history缓存的状态
    this.$destroy(true)
    next()
  },

}
</script>

<style lang="less" scoped>
  .project-list {

    .card-title {
      font-size: 0;

      a {
        color: rgba(0, 0, 0, 0.85);
        margin-left: 12px;
        line-height: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;

        &:hover {
          color: #1890ff;
        }
      }
    }
    .card-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }
    .project-item {
      display: flex;
      margin-top: 8px;
      overflow: hidden;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      a {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        flex: 1 1 0;

        &:hover {
          color: #1890ff;
        }
      }
      .datetime {
        color: rgba(0, 0, 0, 0.25);
        flex: 0 0 auto;
        float: right;
      }
    }
    .ant-card-meta-description {
      color: rgba(0, 0, 0, 0.45);
      height: 44px;
      line-height: 22px;
      overflow: hidden;
    }
  }

  .item-group {
    padding: 20px 0 8px 24px;
    font-size: 0;
    a {
      color: rgba(0, 0, 0, 0.65);
      display: inline-block;
      font-size: 14px;
      margin-bottom: 13px;
      width: 25%;
    }
  }

  .members {
    a {
      display: block;
      margin: 12px 0;
      line-height: 24px;
      height: 24px;
      .member {
        font-size: 14px;
        color: rgba(0, 0, 0, .65);
        line-height: 24px;
        max-width: 100px;
        vertical-align: top;
        margin-left: 12px;
        transition: all 0.3s;
        display: inline-block;
      }
      &:hover {
        span {
          color: #1890ff;
        }
      }
    }
  }

  .mobile {

    .project-list {

      .project-card-grid {
        width: 100%;
      }
    }

    .more-info {
      border: 0;
      padding-top: 16px;
      margin: 16px 0 16px;
    }

    .headerContent .title .welcome-text {
      display: none;
    }
  }
  #tb_wait td{
    text-align: center;
    align:center;
  }
  #tb_data_show td{
    text-align: center;
    align:center;
  }
  #bindwx_div{
    -moz-border-radius:10px;  /* for Firefox */
    -webkit-border-radius:10px; /* for Webkit-Browsers */
    border-radius:10px; /* regular */
    opacity:0.5; /* Transparent Background 50% */
  }
  .ant-carousel .slick-slide {
    text-align: center;
    background: #364d79;
    overflow: hidden;
  }

  .ant-carousel .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
  }
  .ant-carousel .custom-slick-arrow:before {
    display: none;
  }
  .ant-carousel .custom-slick-arrow:hover {
    opacity: 0.5;
  }
  .tb_title{
    font-weight: bold;
    text-align: left;
  }
  .table-wrapper {
    width:  calc(100vw - 700px);
  }
  /deep/ .ant-table-tbody > tr > td {
    padding: 0px!important;
    height: 20px;
  }
  /deep/ .ant-table-thead > tr > th {
    padding: 0px!important;
    height: 20px;
  }

</style>
